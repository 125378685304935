<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Generated Audio Clips</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <button type="button" @click="addVoicemodalOpen" class="header-btn-outer btn btn-primary">
                Generate New Clip
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="voicefilesList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
            :paginator="true" :rows="items_per_page" :lazy="true" dataKey="dv1" :rowHover="true"
            :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="aliasname" header="Audio Clip Name" headerStyle="width: 40%" bodyStyle="width: 40%">
                <template #body="{ data }">
                    <div class="text-capitalize">
                        {{ data.dv2 ? data.dv2 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="language" header="Language" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                    <div class="text-capitalize">
                        {{ data.dv7 ? data.dv7 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="charges" header="Audio File" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                    <div v-if="data.dv4 != null && data.dv4 != ''"><audio controls controlsList="download">
                            <source :src="'https://storage.googleapis.com/' + this.storagefilebucketname + '/voice-recordings/' + data.dv4" type="audio/mpeg" />
                        </audio>
                    </div>
                    <div v-if="data.dv4 == null">-</div>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- Add new modal start here  -->
    <div class="modal-mask" v-if="addvoicefileDialogflag">
        <div class="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Generate Audio</h5>
                    <button type="button" class="btn-close" @click="addVoicemodalClose()"></button>
                </div>
                <div class="modal-body modal-body-scroll-outer">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Name Your Clip<span class="text-danger">*</span></label>
                                <input type="text" v-model="add.aliasname" class="form-control"
                                    placeholder="Provide a short Name for the Clip you want to Generate" maxlength="250" autocomplete="off" />
                                <div class="custom-error" v-if="v$.add.aliasname.$error">
                                    {{ v$.add.aliasname.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Select Language<span class="text-danger">*</span></label>
                                <Multiselect v-model="add.languagevalue" :options="languagelist" :searchable="true"
                                    label="label" placeholder="Select Language"
                                    class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true"
                                    noOptionsText="No Result Found" :object="true" />
                                <div class="custom-error" v-if="v$.add.languagevalue.$error">
                                    {{ v$.add.languagevalue.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label for="formmessageInput" class="form-label">Type or Paste the Text in {{ this.add.languagevalue.label ? this.add.languagevalue.label : "selected Language" }} to generate the audio clip<span
                                        class="text-danger">*</span></label>
                                <textarea type="text" v-model="add.message" class="form-control" id="formmessageInput"
                                    placeholder="Type Text or Paste here" autocomplete="off" rows="3"></textarea>
                                <div class="custom-error" v-if="v$.add.message.$error">
                                    {{ v$.add.message.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-end">
                            <button type="button" class="btn user-role-btn text-center" @click="createVoiceFileSubmitBtn"
                                :disabled="showaddloader" style="width: 115px;">
                                <span v-if="!showaddloader">Generate</span>
                                <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                    v-if="showaddloader"></div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Add new modal end here  -->
</template>
<script>
import ApiService from "../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            voicefilesList: null,
            totalRecords: 0,
            page_no: 0,
            items_per_page: 30,
            loading: false,

            languagelist: [],
            add: {
                aliasname: '',
                message: '',
                languagevalue: '',
            },
            addvoicefileDialogflag: false,
            showaddloader: false,
            storagefilebucketname: '',
        };
    },
    validations() {
        return {
            add: {
                aliasname: { required: helpers.withMessage("Please enter alias name", required), },
                message: { required: helpers.withMessage("Please enter message", required), },
                languagevalue: { required: helpers.withMessage("Please select language", required), },
            },
        };
    },
    mounted() {
        this.getVoiceRecordings();
        this.getlanguages();
        this.client_info = JSON.parse(localStorage.client_info);
        this.storagefilebucketname = this.client_info.maa24;
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        getlanguages(e) {
            this.ApiService.getlanguages(e).then((data) => {
                if (data.status == 200) {
                    //this.languagelist = data.data;
                    // removed dropdown row from specific static id
                    let datafk = 1;
                    let out = [];
                    for(let i=0; i<data.data.length; i++){
                        if(data.data[i]['value'] != datafk){
                            out.push(data.data[i])
                        }
                    }
                    this.languagelist = out
                } else {
                    this.languagelist = [];
                }
            });
        },
        getVoiceRecordings(e) {
            this.loading = true;
            this.ApiService.getVoiceRecordings(e).then((items) => {
                if (items.status == 200) {
                    this.voicefilesList = items.data;
                    this.totalRecords = items.totalCount;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.voicefilesList = null;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getVoiceRecordings({ page_no: this.page_no, items_per_page: this.items_per_page });
        },
        addVoicemodalOpen() {
            this.addvoicefileDialogflag = true;
            this.add.aliasname = '';
            this.add.message = '';
            this.add.languagevalue = '';
        },
        addVoicemodalClose() {
            this.addvoicefileDialogflag = false;
            this.add.aliasname = '';
            this.add.message = '';
            this.add.languagevalue = '';
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        createVoiceFileSubmitBtn() {
            this.v$.add.$validate();
            let fields = {};
            fields["dv2"] = this.add.aliasname;
            fields["dv3"] = this.add.message;
            fields["dv6"] = this.add.languagevalue.value;
            fields["dv7"] = this.add.languagevalue.label;
            console.log(fields);
            if (!this.v$.add.$error) {
                this.showaddloader = true;
                this.ApiService.addVoiceRecordings(fields).then((items) => {
                    if (items.status === 200) {
                        this.showaddloader = false;
                        this.addvoicefileDialogflag = false;
                        var successMsg = items.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getVoiceRecordings();
                        this.add.aliasname = '';
                        this.add.message = '';
                        this.add.languagevalue = '';
                    } else {
                        this.addvoicefileDialogflag = true;
                        var errorMsg = items.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.showaddloader = false;
                    }
                });
            }
        }
    },
};
</script>
<style scoped>
.btn.user-role-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  min-width: 130px;
}

.user-role-btn:focus {
  box-shadow: none;
}
</style>